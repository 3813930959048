import { useState, useEffect } from 'react';
import { ImgType, VideoIframe } from './types';
import axios from 'axios';
import Slider from 'react-slick';
// import ArrowNext from "../Arrows/ArrowNext";
// import ArrowPrev from "../Arrows/ArrowPrev";

interface IframePlaylistProps {
  id: string;
  videoItems?: VideoIframe[];
  defaultVideoItem?: VideoIframe; // default
  playlistId?: string;
  youtubeAPI?: string; // must be present if playlist
}

const IframePlaylist = ({
  id,
  playlistId,
  videoItems,
  youtubeAPI,
  defaultVideoItem,
}: IframePlaylistProps) => {
  const [currentItem, setCurrentItem] = useState<VideoIframe>(
    defaultVideoItem
      ? defaultVideoItem
      : { title: '', videoId: '', imgType: 'maxres' }
  );
  const [items, setItems] = useState(videoItems);

  useEffect(() => {
    (window as any).enableYoutubeAPI(); // enable youtube api

    if (!playlistId) return;
    // handleRequest();
  }, []);

  // handle request from youtube
  // async function handleRequest() {
  //   const playlist_id = playlistId;
  //   const youtube_api = youtubeAPI;
  //   const video_list = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlist_id}&fields=items(snippet(resourceId%2FvideoId%2Cthumbnails%2Fmedium%2Furl%2Ctitle))&key=${youtube_api}`;

  //   const { data } = await axios.get(video_list);

  //   const filteredData: VideoIframe[] = [];

  //   data.items.map((item: any) => {
  //     filteredData.push({
  //       videoId: item.snippet.resourceId.videoId,
  //       title: item.snippet.title,
  //     });
  //   });

  //   // sets the data
  //   setItems(filteredData);
  //   setCurrentItem(filteredData[0]);
  // }

  function handlePlayButton(e: any) {
    e.preventDefault();

    const target = e.currentTarget;
    const videoId = target.getAttribute('data-videoid');
    const placeholder = document.getElementById(`video-placeholder-${videoId}`);
    // setActiveId(videoId ? videoId : "no videoid found"); // sets the active id

    const playerId = 'player-' + videoId;
    const htmlEl = document.createElement('div');
    htmlEl.id = playerId;
    placeholder?.remove();
    target!.insertAdjacentElement('afterbegin', htmlEl);

    // starts the youtube player
    (window as any).formYoutubePlayerLaunch(playerId, videoId);
  }

  if (!items) return null;

  return (
    <div
      id="iframe-playlist"
      className="w-full relative flex flex-col lg:flex-row justify-center items-center p-[2vw]"
    >
      <div className="flex flex-col w-full lg:w-[40%] h-[80vh] overflow-y-scroll overflow-x-hidden">
        {items &&
          items.map((item) => (
            <div
              key={item.videoId}
              className={`h-[40vh] md:h-[70vh] outline-none relative justify-center items-center cursor-pointer w-full px-[2vh] py-[2vh]`}
              data-videoid={item.videoId}
              onClick={(e) => {
                handlePlayButton(e);

                setCurrentItem(item);
              }}
            >
              <img
                id={`video-placeholder-${item.videoId}`}
                className="opacity-100 h-full object-cover mx-auto rounded-primary"
                src={`https://i3.ytimg.com/vi/${item.videoId}/hqdefault.jpg`}
                alt={item.title}
              />
              <span className="text normal--size mt-2 block text-shadow overflow-hidden whitespace-nowrap text-ellipsis">
                {item.title}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default IframePlaylist;
