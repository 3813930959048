import { socialIconsData } from '../Navigation/Navigation';
import SocialIcons from '../SocialIcons/SocialIcons';
import LOGO_GIF from '../../assets/images/Zyah Belle Cartoon GIF.gif';
import { RoutePattern } from '../../routes/RoutePattern';
import { menuData } from '../Navigation/data';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store';

export default function Footer() {
  const { currentRoute } = useSelector((state: RootState) => state.Routes);
  const navigate = useNavigate();

  return (
    <div id={RoutePattern.CONNECT} className="w-5/6 mx-auto py-[2vh]">
      <div className="py-[1vw] border-b-[.2vh] flex flex-col-reverse items-center md:items-end justify-between lg:flex-row">
        <div className="flex flex-row gap-[2vh]">
          {menuData.map(
            (item, idx) =>
              item.smooth && (
                <button
                  key={idx}
                  onClick={() => {
                    if (currentRoute !== '/') {
                      navigate('/#' + item.name);
                      return;
                    }
                    document.getElementById(item.name)?.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }}
                  className={`footer--link 
        ${currentRoute.includes(item.name) && 'active'}
        ${currentRoute !== '/' && 'v2'}
        `}
                >
                  {item.name}
                </button>
              )
          )}
          {/* <span className="text-[3vw] md:text-[.8vw]">
            Copyright &copy; 2024. All rights reserved.
          </span> */}
        </div>
        <div className="flex gap-[2vh] justify-between items-center flex-col">
          <img
            className="w-[50vw] lg:w-[10vw]"
            src={LOGO_GIF}
            alt="zyah belle"
          />
          <SocialIcons data={socialIconsData} />
        </div>
      </div>
      <span className="text-center md:text-right block mt-[2vh] text-[2vw] md:text-[.8vw]">
        Copyright &copy; 2024. All rights reserved.
      </span>
    </div>
  );
}
