import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const SocialLink = styled.a`
  :not(:last-child) {
    margin-right: 0.8rem;
  }
`;

export interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

interface SocialIconProps {
  pandoraIconWidth?: string;
  iconWidth?: string;
  color?: string;
  data: SocialIconsTypes[];
}

export default function SocialIcons({
  iconWidth,
  pandoraIconWidth,
  color = "text-white",
  data,
}: SocialIconProps) {
  return (
    <ul className="flex flex-row justify-center items-center text-white gap-x-[2vh]">
      {data.map((item, idx) => (
        <SocialLink
          key={idx}
          href={item.link}
          rel="noreferrer"
          target="_blank"
          className={`${color} hover:opacity-80`}
        >
          {item.icon ? (
            <FontAwesomeIcon icon={item.icon as any} />
          ) : item.isPandora ? (
            <img
              src={item.img}
              className={pandoraIconWidth}
              style={{
                filter:
                  "invert(8%) sepia(99%) saturate(6379%) hue-rotate(355deg) brightness(90%) contrast(94%)",
              }}
            />
          ) : (
            <img
              src={item.img}
              className="w-[2.2vh]"
              style={{
                filter: "invert(100%)",
              }}
            />
          )}
        </SocialLink>
      ))}
    </ul>
  );
}
