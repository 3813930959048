import Slider from 'react-slick';
import { SlideItem } from '../../../components';
import { SlideItemProps } from '../../../components/SlideItem/SlideItem';

const data: SlideItemProps[] = [
  {
    img: 'https://cloudinary-cdn.ffm.to/s--bnE4Cwx1--/f_webp/https%3A%2F%2Fimagestore.ffm.to%2Flink%2Fb4ccae028693356944d27a8865b11e80.jpeg',
    bigText: 'Lyin',
    smallText: 'Zyah Belle',
    to: 'https://zyahbelle.ffm.to/lyin',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/0bc0e7e9-44d9-4b6f-bf51-161e8ff9e967/artwork-440x440.jpg',
    bigText: 'Yam Grier',
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/YamGrier',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/21755c1f-1a73-402d-8063-6d6d3e6f816f/artwork-440x440.jpg',
    bigText: "Who's Listening Anyway?",
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/WhosListeningAnyway',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/30d13d69-81f1-4090-8568-3a3bd8f55f95/artwork-440x440.jpg',
    bigText: 'Sugawater',
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/sugawater#music-services',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/c2c9df0b-e362-483c-9880-7cbbe81c31e3/artwork-440x440.jpg',
    bigText: 'DND - Single',
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/ZyahDND',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/6164531b-00a4-47c6-a33a-12ed7dcdb2ed/artwork-440x440.jpg',
    bigText: 'I Think That I Love You (feat. Romderful)',
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/ITTILY',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/3f97eaa9-8610-42c7-8cba-462ad1583691/artwork-440x440.jpg',
    bigText: 'You Got It (feat. Rexx Life Raj)',
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/ZyahYouGotIt',
  },
];

export default function Music() {
  return (
    <div
      id="music"
      className="pt-[5vh] md:min-h-screen w-5/6 md:w-3/4 mx-auto flex flex-col justify-center"
    >
      <Slider
        className="w-full z-10"
        slidesToShow={3}
        slidesPerRow={2}
        slidesToScroll={1}
        swipe={false}
        arrows={false} // false for now
        dots={false} // false for now
        responsive={[
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              dots: true,
              arrows: false,
              swipe: true,
            },
          },
        ]}
      >
        {data.map((item, idx) => (
          <SlideItem
            key={idx}
            img={item.img}
            bigText={item.bigText}
            smallText={item.smallText}
            to={item.to}
            showPlayIcon
          />
        ))}
      </Slider>

      <div className="heading heading--2">
        <h2 className="heading--text">music</h2>
      </div>
    </div>
  );
}
