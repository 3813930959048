import { Field, Form, Formik } from 'formik';
import Swal from 'sweetalert2';

import useSheetSubscribe, {
  error,
  success,
} from '../../hooks/useSheetSubscribe';
import { RoutePattern } from '../../routes/RoutePattern';

const Subscribe = () => {
  const { submitForm } = useSheetSubscribe();

  return (
    <div
      id={RoutePattern.CONNECT}
      className="pt-[10vh] -mt-[1vh] relative z-20 bg-dark"
    >
      <Formik
        initialValues={{
          email: '',
          endpoint:
            'https://script.google.com/macros/s/AKfycbyPulYV9lVkRGdfBZXdCSYu0pywGn4PiHO72VpYhEtCFPgO-NG1kx__tpX0bCAcJ8gIQg/exec',
        }}
        onSubmit={async (values, { resetForm }) => {
          try {
            const submitted = await submitForm(values);

            if (!submitted) {
              throw new Error();
            }

            resetForm();
            success({ title: 'Thanks for subscribing!' });
          } catch (err) {
            console.log(err);
            error({
              title: 'Inputs are not submitted',
              text: 'Please try again',
            });
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col justify-center items-center p-5 w-full mx-auto">
            <div className="flex flex-col justify-center items-center w-full md:w-auto">
              <div className="text-center">
                <h4 className="heading heading--4 block font-bold uppercase">
                  Stay updated
                </h4>
                <span className="uppercase text normal--size leading-[100%]">
                  the latest updates straight to your inbox
                </span>
              </div>

              <Field
                className="w-full mt-[2vh] bg-transparent border-b border-opacity-50 border-white text-[3vw] md:text-[.8vw] p-[1vw]"
                type="text"
                name="email"
                placeholder="Enter your email address"
              />

              <button type="submit" className="btn btn--light mt-[4vh]">
                {isSubmitting ? 'Submitting...' : 'subscribe'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Subscribe;
