import { RoutePattern } from '../../routes/RoutePattern';
import SHOWCASE_IMG from '../../assets/images/photos-2024/1.webp';
import IMG_1 from '../../assets/images/photos-2024/2.webp';
import IMG_2 from '../../assets/images/photos-2024/3.webp';
import IMG_3 from '../../assets/images/photos-2024/4.webp';
import IMG_4 from '../../assets/images/photos-2024/5.webp';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const dataImgs = [
  {
    img: IMG_1,
  },
  {
    img: IMG_2,
  },
  {
    img: IMG_3,
  },
  {
    img: IMG_4,
  },
];

export default function About() {
  return (
    <div
      id={RoutePattern.ABOUT}
      className="py-[10vh]  min-h-screen  overflow-hidden relative"
    >
      <div className="w-full md:w-5/6 mx-auto flex flex-col justify-center">
        <div className="flex flex-col lg:flex-row gap-[2vw]">
          <div className="w-full">
            <h4 className="px-[5vw] text-primary font-bold text-[10vw] lg:text-[3vw] leading-[100%]">
              ZYAH BELLE
            </h4>
            <div className="font-tertiary  px-[5vw] text-[7vw] md:text-[1.5vw] mt-[2vh] max-h-[90vh] overflow-y-scroll">
              <p>
                When you know yourself, nothing can stop you...
                <br />
                <br />
                Zyah Belle both realizes and understands her power as a singer,
                songwriter, producer, and arranger. She’s reached this
                realization through a tireless grind, going from countless open
                mics and gigs at dimly lit bars in Northern California to
                collaborating with some of the most celebrated icons in history.
                At the same time, she’s also grown into an uncompromising and
                undeniable solo artist in her own right with widespread critical
                acclaim and millions of streams.
                <br />
                <br />
                However, she only turns up the volume on her 2022 independent
                full-length debut LP, Yam Grier. “I know exactly who I am,” she
                affirms. “When you face a woman who knows herself, you can’t
                stop her, because she sets her own standards. We make our own
                rules. I’ve already gotten passed some of my worst fears; I’m
                unstoppable.”
                <br />
                <br />
                Hailing from the small town of Vallejo, CA, she initially
                discovered music in church. Mom served as the choir director, so
                Zyah participated in children’s choir as soon as she could.
                Inspired by her two older sisters, she simultaneously developed
                a passion for R&B. Raised by a single mother and her siblings,
                she found herself surrounded by “powerful women.“
                <br />
                <br />
                “Before I even turned on the television to absorb the influence
                of Jill Scott and Angela Bassett, those black women in my home
                set the standard for me,” she notes.
                <br />
                <br />
                During high school, she landed the lead role of Dorothy in the
                Wizard of Oz, adapting to the stage right away. Following high
                school, she cut her teeth at countless open mics and state
                fairs. Eventually, she caught the attention of various heavy
                hitters.
                <br />
                <br />
                Among many highlights, she co-wrote and collaborated with Snoop
                Dogg on “Trouble,” which served as the end credits song for Dog
                Gone Trouble on NETFLIX. She lent her voice to Terrace Martin’s
                “Never Saw It Coming” [feat. Zyah Belle & Problem] in addition
                to Too $hort’s “I Stay Up” and “Get Top”. Recording and
                performing with Kanye West’s Sunday Service Choir, her voice
                boomed on chart-topping #1 releases such as ye and Jesus Is
                King. The latter even earned “Top Christian Album” and “Top
                Gospel Album” at the 2020 Billboard Music Awards and “Best
                Contemporary Christian Music Album” at the 2021 GRAMMY® Awards.
                Following new Levels [2016] and IX [2019], Zyah’s 2021 Who’s
                Listening Anyway EP racked up millions of streams and incited
                widespread critical acclaim. Vibe touted it on “The 21 Best R&B
                Albums of 2021” and went on to declare, “Belle is able to fold
                the styles of Erykah Badu’s melodic rawness, Anderson Paak’s
                wittiness, and the essence of ‘90s neo-soul into a terse
                seven-track body of work.”
                <br />
                <br />
                Working with the likes of Hollywood Cole, Sam Barsh, and PBnJ,
                she levels up yet again on Yam Grier. Cut at the legendary
                Doghouse Studio in Los Angeles, she strikes a balance between
                21st-century soul and classic seventies spirit—suggested by the
                title Yam Grier.
                <br />
                <br />
                “Yam is a reference to a woman with a fuller body,” she states.
                “Grier is obviously Pam Grier. A friend had nicknamed me Yam
                Grier, and I started using it on social media. Pam Grier’s
                characters signified so much for the black community. In
                addition to the music of the era, we took inspiration from the
                freeness of black people—especially women—in the seventies. It’s
                all about being empowered, carefree, and strong as a black
                woman. That’s what we’re channeling.”
                <br />
                <br />
                Zyah initially paved the way for Yam Grier with “Not The One”
                and “DND”. Of the latter, Soul Bounce instantly applauded “her
                powerful vocals and boisterous personality”, and Rated R&B
                likened it to “a jazzy version of Destiny’s Child ‘Bug a Boo’.
                ”Meanwhile, the single “Back To Back” floats on clean guitar
                through a laidback beat. Vulnerable verses give way to a
                chantable chorus punctuated by her robust range.
                <br />
                <br />
                “In its entirety, the album is about a woman stepping into her
                own power, certainty, and confidence”, she reveals. “On this
                journey, I feel like every woman has a relationship or
                situationship that is more representative of her past than it is
                of who she is today. We all have moments where we double back.
                It’s like, ‘Shit, I’m messing with this guy again who I know is
                no good for me’.”
                <br />
                <br />
                With a swell of cinematic production, “Closure” leans into a
                smoked-out jazz vibe as it picks up a thread from earlier on the
                record.
                <br />
                <br />
                “If you think of the story, the woman in‘ Back To Back’needs to
                move on,” she elaborates. “Closure always comes before you
                leave. It was the perfect puzzle piece in the story we were
                telling.”
                <br />
                <br />
                Organic percussion, glossy synths, and shimmering piano
                accompany her gorgeous intonation on the finale “Go” where she
                provides an affirmation for herself, “Wherever you want, go.”
                <br />
                <br />
                It was based on an astrology reading I was listening to before
                the session,” she recalls. “These days, people feel like they’re
                in a rush for certain things, and it’s difficult to be patient.
                I’ve been doing this for ten years, and I was having a hard time
                thinking about it. However, I felt more encouraged to tell
                myself, ‘I can do whatever I want. I can be and feel whatever I
                want’. I don’t have to work on anybody’s timetable. I’m on my
                own time. This is for me. “‘Go’ set the tone for the whole
                record.”
                <br />
                <br />
                In the end, Zyah might just make you feel unstoppable too. “When
                you listen to this, I hope you identify with something in it,”
                she leaves off. “I feel like there’s something in there for
                everybody. I hope it inspires you or helps you in some way.”
              </p>
            </div>
          </div>
          <div className="w-5/6 mt-[4vh] ml-auto hidden md:block lg:w-full">
            <img
              className="w-full object-cover object-top h-screen rounded--left md:rounded-primary"
              src={SHOWCASE_IMG}
              alt="zyah belle"
            />
          </div>
        </div>
      </div>
      <div className="px-[2vw] mt-[4vh] z-10 relative w-full">
        <Slider
          className="w-full z-10"
          slidesToShow={4}
          slidesToScroll={1}
          arrows={false} // false for now
          dots={false} // false for now
          responsive={[
            {
              breakpoint: 768,
              settings: {
                swipe: true,
                slidesToShow: 1,
                slidesPerRow: 1,
              },
            },
          ]}
        >
          {dataImgs.map((item, idx) => (
            <div key={idx} className="pl-[5vw]">
              <img
                className="h-[50vh] w-full object-cover rounded-primary"
                src={item.img}
                alt="zyah belle"
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="heading heading--2">
        <h2 className="heading--text">About</h2>
      </div>
    </div>
  );
}
