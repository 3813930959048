import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import BG_DESKTOP_INTRO_LONG from '../../assets/videos/bg.intro.desktop.mp4';
import BG_MOBILE_INTRO_LONG from '../../assets/videos/bg.intro.mobile.mp4';
import BG_DESKTOP_LOOP from '../../assets/videos/bg.desktop.mp4';
import VIDEOGIF from '../../assets/videos/bg.mobile.gif';
import BG_MOBILE_LOOP from '../../assets/videos/bg.mobile.mp4';
import VideoFallback from './VideoFallback';

export default function IntroLoopBG() {
  const videoRef: any = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isDonePlaying, setIsDonePlaying] = useState(false);

  // check if paused
  function handleTimeUpdate(e: React.SyntheticEvent<HTMLVideoElement, Event>) {
    const target = e.currentTarget;

    if (target.paused) {
      setIsPaused(target.paused);
    }
  }

  function handleIntroVideoEnded(e: SyntheticEvent<HTMLVideoElement, Event>) {
    setIsDonePlaying(true);

    const dur = e.currentTarget.duration - 0.2;
    if (e.currentTarget.currentTime <= dur) {
      return;
    }

    const loopVideo = document.getElementById('loop-video') as HTMLVideoElement;
    const introVideo = document.getElementById(
      'intro-video'
    ) as HTMLVideoElement;

    introVideo.pause();

    loopVideo.currentTime = 0; // reset time

    setTimeout(() => {
      loopVideo.style.opacity = '100';
      loopVideo.play();
    }, 100);
  }

  return (
    <>
      {isPaused ? (
        <img
          src={VIDEOGIF}
          alt="video"
          className="w-full h-full object-cover relative z-20"
        />
      ) : (
        <div>
          <video
            ref={videoRef}
            id="intro-video"
            className="w-full object-cover object-center z-0 absolute top-0 left-0 h-full"
            preload="metadata"
            muted
            autoPlay
            playsInline
            onTimeUpdate={isDonePlaying ? handleTimeUpdate : undefined}
            onEnded={handleIntroVideoEnded}
          >
            <source
              type="video/mp4"
              src={
                window.innerWidth >= 768
                  ? BG_DESKTOP_INTRO_LONG
                  : BG_MOBILE_INTRO_LONG
              }
            />
          </video>
          <video
            id="loop-video"
            preload="metadata"
            playsInline
            muted
            loop
            autoPlay
            className="z-10 opacity-0 w-full object-cover object-center absolute top-0 left-0 h-full"
          >
            <source
              src={window.innerWidth >= 768 ? BG_DESKTOP_LOOP : BG_MOBILE_LOOP}
              type="video/mp4"
            />
          </video>
        </div>
      )}
    </>
  );
}
