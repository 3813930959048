import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  StyledLink,
  StyledWrapper,
  StyledImg,
  StyledIcon,
} from './Styled.components';

export interface SlideItemProps {
  img: string;
  bigText: string;
  smallText?: string;
  to: string;
  showPlayIcon?: boolean;
}

export default function SlideItem({
  img,
  bigText,
  smallText,
  to,
  showPlayIcon,
}: SlideItemProps) {
  return (
    <StyledLink
      href={to}
      target="_blank"
      rel="noreferrer"
      className="relative mx-[2vh] my-[4vh]"
    >
      <StyledWrapper className="rounded-primary relative overflow-hidden z-0">
        <StyledImg
          src={img}
          alt="sample showcase"
          className="z-0 w-full object-cover"
        />
      </StyledWrapper>
      <div className="absolute bottom-0 w-full left-0 z-20 p-[2vw]">
        <h4 className="text big--size leading-[100%] whitespace-nowrap text-ellipsis w-full block overflow-hidden text-shadow">
          {bigText}
        </h4>
        <span className="text normal--size leading-0 text-shadow">
          {!!smallText && smallText}
        </span>
      </div>
    </StyledLink>
  );
}
