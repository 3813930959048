import { RoutePattern } from '../../routes/RoutePattern';

export interface RouteTypes {
  name: string;
  to?: string;
  linked?: string;
  smooth?: boolean;
  isAnchor?: boolean;
  dropdown?: RouteTypes[];
  self?: string;
  nextTo?: string;
}

export const menuData: RouteTypes[] = [
  {
    name: RoutePattern.MUSIC,
    smooth: true,
  },
  {
    name: RoutePattern.VIDEOS,
    smooth: true,
  },
  {
    name: RoutePattern.ABOUT,
    smooth: true,
  },
  {
    name: RoutePattern.TOUR,
    smooth: true,
  },
  {
    name: RoutePattern.CONNECT,
    smooth: true,
  },
];
