import axios from 'axios';
import { useEffect, useState } from 'react';
import { Carousel } from '../../../components';
import VideoItem from './VideoItem';
import IframePlaylist from '../../../components/IframePlaylist/IframePlaylist';
import { ImgType, VideoIframe } from '../../../components/IframePlaylist/types';

const items: VideoIframe[] = [
  {
    videoId: 'HxeYESn6yIw',
    title: 'Say My Name (Official Music Video) - Zyah Belle feat. Tempest',
    imgType: 'maxres',
  },
  {
    videoId: 'jhN4JV54wOM',
    title:
      'Zyah Belle - I Think That I Love You feat. ROMderful (Official Music Video)',
    imgType: 'maxres',
  },
  {
    videoId: 'kUZ2XnnbuYQ',
    title: 'DND (Official Music Video) - Zyah Belle',
    imgType: 'hq',
  },
  {
    videoId: 'L2nEFsR-fDk',
    title:
      '#ABlackGirlSings in an enchanted forest (Zyah Belle - I Think That I Love You)',
    imgType: 'maxres',
  },
  {
    videoId: 'p4HN1uqbs6M',
    title:
      "Singer-Songwriter Zyah Belle Performs Neo-Soul Stylings On 'Go' | On Site with SpringHill",
    imgType: 'maxres',
  },
  {
    videoId: 'ju4teu6nWTI',
    title: 'Zyah Belle - Sugawater | ASCAP Urban Presents On the Come Up 2022',
    imgType: 'maxres',
  },
];

export default function Videos() {
  // const [items, set] = useState<any>();

  // useEffect(() => {
  //   const playlist_id = 'PLATsDbTgimKDvvjA8HdS-G4rhNZNCN342';
  //   const youtube_api = 'AIzaSyCapJ5cgPo02rxlhEeHSEJwgV-nQRKnucA';
  //   const video_list = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlist_id}&fields=items(snippet(resourceId%2FvideoId%2Cthumbnails%2Fmedium%2Furl%2Ctitle))&key=${youtube_api}`;

  //   getVideos(video_list);
  // }, []);

  // async function getVideos(target: string) {
  //   const { data } = await axios.get(target);
  //   set(data.items);
  // }

  // tslint:disable-next-line: curly
  if (!items) return <></>;

  return (
    <div
      id="videos"
      className="pt-[5vh]  md:-mt-[5vh] min-h-screen flex flex-col justify-center  bg-secondary overflow-hidden"
    >
      <div className="">
        {/* <Carousel v={3}>
        {items.map((item: VideoDataTypes, idx: number) => (
          <VideoItem
            key={idx}
            title={item.snippet.title}
            thumbnail={item.snippet.thumbnails.medium.url}
          />
        ))}
      </Carousel> */}
        <div>
          <IframePlaylist
            id="iframe"
            videoItems={items}
            defaultVideoItem={items[0]}
          />
        </div>

        <div className="heading heading--2">
          <h2 className="heading--text">videos</h2>
        </div>
      </div>
    </div>
  );
}
